export const stations = [
    {
        value: 4,
        label: 'BS_04_AMPELOKHPOI',
        additionalInfo: 'Bric Wall 25cm, Telco 4KW'
    },
    { value: 5, label: 'BS_05_INFOATH', additionalInfo: 'Polyurethane Panel 5cm, Telco 4KW' },
    {
        value: 6,
        label: 'BS_06_AMPELOKHPOI',
        additionalInfo: 'Stainless Steel 2mm, 3KW'
    },
    {
        value: 7,
        label: 'BS_07_STEGHPATRIDOS',
        additionalInfo: 'Bric Wall + Insulation 10cm/5cm/10cm, Telco 4KW'
    },
    {
        value: 8,
        label: 'BS_08_LEOFOROS',
        additionalInfo: 'Polyurethane Panel 8cm, Telco 4KW'
    },
    {
        value: 9,
        label: 'BS_Siosinoglou_4250W_BigData_schedule',
        additionalInfo: 'Bric Wall 25cm, Telco 4250W'
    },
    { value: 10, label: 'BS_Metaxa_1000W_BigData_schedule', additionalInfo: 'Stainless Steel, Telco 1000W' },
    {
        value: 11,
        label: 'BS_Ilioupoli_10000W_BigData_schedule',
        additionalInfo: 'Bric Wall10-5-10cm, Telco 10000W'
    },
    {
        value: 12,
        label: 'BS_Amvrakias_1100W_BigData_schedule',
        additionalInfo: 'PU Panel 5cm, Telco 1100W'
    }
];

export const models = [
    { value: 1, label: 'Απλό μοντέλο χωρίς Energy Eficiency Measure' },
    { value: 2, label: 'Φωτοβολταϊκό' },
    { value: 3, label: 'Φωτοβολταϊκό και Εξαερισμός' },
    { value: 4, label: 'Εξαερισμός' }
];
