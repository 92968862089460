import { Box, Button, Grid } from '@mui/material';
import { authorizeBDA, fetchMessages } from 'apis/cslab';
import { DateInput, Dropdown } from 'qubCommon/Inputs';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import AreaChartDiagram from '../MainDashboard/Components/AreaChartDiagram';
import ChartWrapper from '../MainDashboard/Components/ChartWrapper';
import { useTranslation } from 'react-i18next';
import { getEfficiencyServerData } from 'apis/controllers/dashboard';
import ErrorDialog from './ErrorDialog';

const DataLog = () => {
    const methods = useForm();
    const [openError, setOpenError] = useState(false);
    const [tempData, setTempData] = useState([]);
    const [wattData, setWattData] = useState([]);
    const [dateFromDefaultValue, setDateFromDefaultValue] = useState('2023-07-01');
    const [count, setCount] = useState(6);
    const { t } = useTranslation();

    useEffect(() => {
        async function fetchData(day) {
            const urlParams = new URLSearchParams();
            urlParams.append('type', 'days');
            urlParams.append('n', day);
            let res;

            try {
                res = await fetchMessages('entries', urlParams);
            } catch {
                authorizeBDA()
                    .then(async (res) => {
                        console.log(res);
                        res = await fetchMessages('entries', urlParams);
                    })
                    .catch((err) => console.log(err));
            }
            console.log(res, 'MelloAngelos');
        }
        const day = methods.getValues('days');
        if (day) {
            fetchData(day);
        }
    }, [methods?.watch('days')]);

    const days = [];
    for (let i = 1; i <= 30; i += 1) {
        const obj = {
            value: i,
            label: i.toString()
        };
        days.push(obj);
    }

    useEffect(() => {}, []);

    const getData = async () => {
        if (methods.getValues('dateFrom') > methods.getValues('dateTo')) setOpenError(true);
        else {
            const res = await getEfficiencyServerData(methods.getValues());
            setTempData(
                res?.tempData?.map((item) => {
                    return { datetime: new Date(item?.datetime), temperature: item?.temperature };
                })
            );
            setWattData(
                res?.wattData.map((item) => {
                    return { datetime: new Date(item?.datetime), wattage: item?.wattage };
                })
            );
        }
    };

    return (
        <>
            <ErrorDialog
                open={openError}
                onClose={() => setOpenError(false)}
                errorMessage="Date From is greater than Date To, please enter valid values."
            />
            <FormProvider {...methods}>
                <Grid container>
                    <Grid key={dateFromDefaultValue} container item xs={12} sx={{ gap: 2, marginBottom: 2 }}>
                        <DateInput
                            id="dateFrom"
                            dateInUse
                            name="dateFrom"
                            label={t('From')}
                            dateFrom="2023-07-01"
                            dateTo="2023-10-01"
                            defaultValue={dateFromDefaultValue}
                        />
                        <DateInput
                            id="dateTo"
                            name="dateTo"
                            label={t('To')}
                            dateInUse
                            dateTo="2023-10-01"
                            dateFrom="2023-07-01"
                            defaultValue="2023-08-01"
                        />
                        <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                            <Button variant="outlined" onClick={getData} sx={{ maxHeight: '40px' }}>
                                {t('View')}
                            </Button>
                        </Box>
                    </Grid>
                    <ChartWrapper xs={12} md={6} title={`${t('Temperature')} (°C)`}>
                        <AreaChartDiagram
                            data={tempData}
                            height={500}
                            xProperty="datetime"
                            lines={[{ dataKey: 'temperature', stroke: '#8884d8', fill: 'url(#color1)' }]}
                        />
                    </ChartWrapper>
                    <ChartWrapper xs={12} md={6} title={`${t('Wattage')} (W)`}>
                        <AreaChartDiagram
                            data={wattData}
                            height={500}
                            xProperty="datetime"
                            lines={[{ dataKey: 'wattage', stroke: '#8180d8', fill: 'url(#color2)' }]}
                        />
                    </ChartWrapper>
                </Grid>
            </FormProvider>
        </>
    );
};

export default DataLog;
