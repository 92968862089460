/* eslint-disable import/no-cycle */
import axiosInstance from '../axiosInstance';

export const dashboardApi = {
    post: async (path, params, cancelToken) =>
        axiosInstance
            .post(`/dashboard${path}`, params, {
                cancelToken: cancelToken?.token
            })
            .then((res) => {
                if (res?.data?.error) throw res.data;
                return res?.data;
            })
            .catch((err) => {
                console.log('err', err);
                throw err.error ? err : err?.response;
            }),
    get: async (path, params, cancelToken) =>
        axiosInstance
            .get(
                `/dashboard${path}`,
                { params },
                {
                    cancelToken: cancelToken?.token
                }
            )
            .then((res) => {
                if (res?.data?.error) throw res.data;
                return res?.data;
            })
            .catch((err) => {
                throw err.error ? err : err?.response;
            })
};

export const getChartsParams = async () => dashboardApi.post('/getChartsParams');

export const getChartsData = async (params) => dashboardApi.post('/getChartsData', params);

export const getCubeData = async (params) => dashboardApi.post('/getCubeData', params);

export const getClusterOptParams = async () => dashboardApi.post('/getClusterOptParams');

export const getSimulationData = async (params) => dashboardApi.post('/getSimulationData', params);

export const getEfficiencyServerData = async (params) => dashboardApi.post('/getEfficiencyServerData', params);
