/* eslint-disable */
import axios from 'axios';

const bdaAuth = axios.create({
    baseURL: 'https://iccs-bigdata.cslab.ece.ntua.gr:9999/api/datastore/bigoptibase'
});

export const authorizeBDA = () => {
    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    };
    const params = new URLSearchParams();
    params.append('grant_type', 'password');
    params.append('client_id', 'bda_client');
    params.append('client_secret', 'bda_secret');
    params.append('username', 'qubitec');
    params.append('password', '3f6654a8b40cc84');
    return axios
        .post('https://iccs-bigdata.cslab.ece.ntua.gr:8443/auth/realms/test_realm/protocol/openid-connect/token', params, config)
        .then((res) => {
            console.log(res);
            if (res?.data?.error) throw res.data;
            const { access_token, refresh_token } = res.data;
            localStorage.setItem('access_token_csl', access_token);
            localStorage.setItem('refresh_token_csl', refresh_token);
            return res?.data;
        })
        .catch((err) => {
            console.log(err);
            throw err.error ? err : err?.response?.data;
        });
};

bdaAuth.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('access_token_csl');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

bdaAuth.interceptors.response.use(
    (response) => response,
    async (error) => {
        // If the error status is 401 and there is no originalRequest._retry flag,
        // it means the token has expired and we need to refresh it
        /* eslint no-underscore-dangle: 0 */
        if (error.response?.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;

            try {
                // const refreshToken = localStorage.getItem('refresh_token_csl');
                // const response = await axios.post('/api/refresh-token', { refreshToken });
                // const { token } = response.data;

                // localStorage.setItem('token', token);

                // // Retry the original request with the new token
                // originalRequest.headers.Authorization = `Bearer ${token}`;
                console.log('i am in here');
                return axios(originalRequest);
            } catch (error) {
                // Handle refresh token error or redirect to login
            }
        }

        return Promise.reject(error);
    }
);

export const fetchMessages = (type, params) => {
    return bdaAuth
        .get(`/${type}`, { params })
        .then((res) => {
            console.log(res);
            if (res?.data?.error) throw res.data;

            return res?.data;
        })
        .catch((err) => {
            console.log(err?.error, err?.config, err?.response?.status, err);
            throw err.error ? err : err?.response?.data;
        });
};
