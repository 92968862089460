import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import { Loadable } from '@qubiteq/qublib';

// permission
import { withPermission } from 'hoc';

// react-router
import { Navigate } from 'react-router-dom';
import MainDashboard from 'views/dashboard/MainDashboard';
import DataCube from 'views/dashboard/DataCube';
import ClusterOptimization from 'views/dashboard/ClusterOptimization';
import EfficiencyServer from 'views/dashboard/EfficiencyServer';
import EnergySimulation from 'views/dashboard/EnergySimulation';

// dashboard routing

const HomeView = withPermission(Loadable(lazy(() => import('views/dashboard/Home'))), {
    unrestricted: true
});
const AccountSettings = withPermission(Loadable(lazy(() => import('views/settings/AccountSettings'))), {
    unrestricted: true
});
const UsersView = withPermission(Loadable(lazy(() => import('views/dashboard/Users').then((module) => ({ default: module.View })))), {
    action: 'View',
    subject: 'Users'
});
const UsersManage = withPermission(Loadable(lazy(() => import('views/dashboard/Users').then((module) => ({ default: module.Manage })))), {
    action: 'Manage',
    subject: 'Users'
});
const RolesView = withPermission(Loadable(lazy(() => import('views/dashboard/Roles').then((module) => ({ default: module.View })))), {
    action: 'View',
    subject: 'Roles'
});
const RolesManage = withPermission(Loadable(lazy(() => import('views/dashboard/Roles').then((module) => ({ default: module.Manage })))), {
    action: 'Manage',
    subject: 'Roles'
});
// admin routing
const ImpersonationView = withPermission(Loadable(lazy(() => import('views/admin/Impersonation'))), {
    action: 'Impersonation',
    subject: 'Admin'
});

const PageNotFound = Loadable(lazy(() => import('views/PageNotFound')));
const ServerError = Loadable(lazy(() => import('views/ServerError')));
const PageUnderConstruction = Loadable(lazy(() => import('views/PageUnderConstruction')));

// user manage
const UserDetails = Loadable(lazy(() => import('views/dashboard/Users/Manage/Details')));
const UserRoles = Loadable(lazy(() => import('views/dashboard/Users/Manage/Roles')));
const UserPermissions = Loadable(lazy(() => import('views/dashboard/Users/Manage/Permissions')));

// role manage
const RolesDetails = Loadable(lazy(() => import('views/dashboard/Roles/Manage/Details')));
const RolesPermissions = Loadable(lazy(() => import('views/dashboard/Roles/Manage/Permissions')));
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = () => [
    {
        path: '',
        element: <MainLayout />,
        children: [
            {
                path: 'home',
                element: <HomeView />
            },
            {
                path: 'user/settings',
                element: <AccountSettings />
            },
            {
                path: '',
                element: <Navigate to="/home" />
            },
            {
                path: 'site-is-temporarily-unavailable',
                element: <ServerError />
            },
            {
                path: '/invite',
                element: (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 'bold', fontSize: 22 }}>
                        Παρακαλώ αποσυνδεθείτε για να αποδεχθείτε την πρόσκληση!
                    </div>
                )
            },
            {
                path: '*',
                element: <PageNotFound />
            }
        ]
    },
    {
        path: 'users',
        element: <MainLayout />,
        children: [
            {
                path: 'view',
                element: <UsersView />
            },
            {
                path: 'manage/:id',
                element: <UsersManage />,
                children: [
                    {
                        path: 'details',
                        element: <UserDetails />
                    },
                    {
                        path: 'roles',
                        element: <UserRoles />
                    },
                    {
                        path: 'permissions',
                        element: <UserPermissions />
                    }
                ]
            }
        ]
    },
    {
        path: 'roles',
        element: <MainLayout />,
        children: [
            {
                path: 'view',
                element: <RolesView />
            },
            {
                path: 'manage/:id',
                element: <RolesManage />,
                children: [
                    {
                        path: 'details',
                        element: <RolesDetails />
                    },
                    {
                        path: 'permissions',
                        element: <RolesPermissions />
                    }
                ]
            }
        ]
    },
    {
        path: 'main-dashboard',
        element: <MainLayout />,
        children: [
            {
                path: 'view',
                element: <MainDashboard />
            }
        ]
    },
    {
        path: 'data-cube',
        element: <MainLayout />,
        children: [
            {
                path: 'view',
                element: <DataCube />
            }
        ]
    },
    {
        path: 'admin',
        element: <MainLayout />,
        children: [
            {
                path: 'impersonation',
                element: <ImpersonationView />
            }
        ]
    },
    {
        path: 'cluster-optimization',
        element: <MainLayout />,
        children: [
            {
                path: 'view',
                element: <ClusterOptimization />
            }
        ]
    },
    {
        path: 'live-data',
        element: <MainLayout />,
        children: [
            {
                path: 'view',
                element: <EfficiencyServer />
            }
        ]
    },
    {
        path: 'energy-simulation',
        element: <MainLayout />,
        children: [
            {
                path: 'view',
                element: <EnergySimulation />
            }
        ]
    }
];

export default MainRoutes;
