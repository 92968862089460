import * as Olap from '@grapecity/wijmo.react.olap';
import * as wjcOlap from '@grapecity/wijmo.olap';
import { Button, Grid } from '@mui/material';
import { useRef } from 'react';

const EnergyCube = ({ data }) => {
    const ngPanel = new wjcOlap.PivotEngine({
        itemsSource: data.map((row) => {
            return { ...row, date: new Date(row?.date) };
        }),
        fields: [
            { binding: 'date', header: 'Month', format: 'MMM' },
            { binding: 'date', header: 'Year', format: 'yyyy' },

            { binding: 'station_name', header: 'Station' },
            { binding: 'energy_mean', header: 'Mean' },
            { binding: 'energy_pred', header: 'Pred' },
            { binding: 'energy_pred_on_pred', header: 'Pred on Pred' }
        ],
        rowFields: ['Month'],
        valueFields: ['Mean', 'Pred', 'Pred on Pred'],
        columnFields: ['Year', 'Station']
    });

    const gridRef = useRef();

    const pgridInitialized = (flex) => {
        gridRef.current = flex;

        flex.itemFormatter = (panel, r, c, cell) => {
            if (panel.cellType === 3) cell.innerHTML = 'Μήνας';
        };
    };

    return (
        <Grid container spacing={2}>
            <Button onClick={() => console.log(gridRef.current)}>hello</Button>
            <Grid item xs={12}>
                <Olap.PivotGrid initialized={pgridInitialized} style={{ height: '98%', width: '98%' }} itemsSource={ngPanel} />
            </Grid>
            <Grid item xs={12}>
                <Olap.PivotChart
                    style={{
                        height: '90%'
                    }}
                    itemsSource={ngPanel}
                    showTitle={false}
                />
            </Grid>
        </Grid>
    );
};

export default EnergyCube;
