import React, { useEffect, useState } from 'react';

function LocalHTMLRenderer() {
    const [htmlContent, setHtmlContent] = useState('');

    useEffect(() => {
        // Replace 'your-local-file.html' with the path to your local HTML file
        fetch('../assets/htmlFiles/html_sample.html')
            .then((response) => response.text())
            .then((data) => setHtmlContent(data))
            .catch((error) => console.error('Error loading HTML file:', error));
    }, []);

    return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
}

export default LocalHTMLRenderer;
