import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Box, Stack, Typography } from '@mui/material';

export default function ErrorDialog({ open, onClose, errorMessage }) {
    return (
        <Dialog open={open} onClose={onClose} maxWidth="md">
            <DialogTitle>
                <Stack direction="row" style={{ gap: 4 }}>
                    <ErrorOutlineIcon color="error" style={{ marginTop: 2 }} />
                    <Box sx={{ display: 'flex', alignItems: 'end' }}>
                        <Typography alignSelf="bottom">Invalid Dates selected.</Typography>
                    </Box>
                </Stack>
            </DialogTitle>
            <DialogContent style={{ marginTop: 12 }}>{errorMessage}</DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary" autoFocus>
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    );
}
