import { Button, Grid, Typography, Stack, Box } from '@mui/material';

const ChartWrapper = (props) => {
    const { children, xs, md, title, showPred, handleShowHide } = props;

    return (
        <Grid container item xs={xs} md={md} spacing={2}>
            <Grid item xs={12}>
                <Stack direction="row" alignItems="center" justifyContent="center" sx={{ width: '100%' }}>
                    <Typography align="center" variant="h5">
                        {title}
                    </Typography>
                    {handleShowHide && (
                        <Button onClick={handleShowHide} sx={{ height: 14 }}>
                            {showPred ? 'Hide Prediction' : 'Show Prediction'}
                        </Button>
                    )}
                </Stack>
            </Grid>
            <Grid item xs={12}>
                {children}
            </Grid>
        </Grid>
    );
};

export default ChartWrapper;
