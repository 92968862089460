import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        debug: true,
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false
        },
        resources: {
            en: {
                translation: {
                    Home: 'Home',
                    Users: 'Users',
                    Roles: 'Roles',
                    Analytics: 'Analytics',
                    AccountSettings: 'Account Settings',
                    GoodMorning: 'Good Morning',
                    GoodEvening: 'Good Evening',
                    Dashboard: 'Dashboard',
                    SelectLanguage: 'Select language',
                    EnterCredsContinue: 'Enter your credentials to continue',
                    LoginEmailUsername: 'Login with email or username',
                    Login: 'Login',
                    Logout: 'Logout',
                    ForgotPassword: 'Forgot my password',
                    DontHaveAcount: "Don't  have an account?",
                    LoginSuccess: 'Login successful',
                    SomethingWrong: 'Something went wrong!',
                    Email: 'Email',
                    Password: 'Password',
                    ConfirmPassword: 'Confirm password',
                    RequiredField: 'Required field',
                    CreateAccount: 'Create account',
                    YourCreds: 'Enter your credentials',
                    AcceptTerms: 'Accept terms and conditions',
                    AcceptTermsError: 'You have to accept the terms and conditions',
                    AlreadyAccount: 'Already have an account?',
                    SignUp: 'Sign up',
                    Firstname: 'Firstname',
                    Lastname: 'Lastname',
                    PhoneNumber: 'Phone number',
                    PasswordsNotMatching: 'Passwords do not match',
                    InvalidDate: 'Invalid date',
                    InvalidPhone: 'Invalid phone',
                    InvalidEmail: 'Invalid email',
                    SiteUnavailable: 'Site is temporarily unavailable :(',
                    PageNotFound: 'Page not found :(',
                    PageUnderConstruction: '🚚 Page is under construction 🚚',
                    Authentication: 'Application',
                    Dashboards: 'Dashboards',
                    'Energy & Telecommunication': 'Energy & Telecommunication',
                    Energy: 'Energy',
                    Telecommunication: 'Telecommunication',
                    Station: 'Station',
                    Year: 'Year',
                    Month: 'Month',
                    Indicator: 'Indicator',
                    January: 'January',
                    February: 'February',
                    March: 'March',
                    April: 'April',
                    May: 'May',
                    June: 'June',
                    July: 'July',
                    August: 'August',
                    September: 'September',
                    October: 'October',
                    November: 'November',
                    December: 'December',
                    Cluster: 'Cluster',
                    Technology: 'Technology',
                    FrequencyMHz: 'Frequency(MHz)',
                    OptMethod: 'Optimization Method',
                    Maps: 'Maps',
                    InitialMap: 'Initial Map',
                    OptimizedMap: 'Optimized Map',
                    SideBySideMaps: 'Side by Side Maps',
                    ClusterOpt: 'Cluster Optimization',
                    base_station: 'Base Station',
                    model: 'Model',
                    EnergySimulation: 'Energy Simulation',
                    EffServer: 'Efficiency Server Data',
                    stations_surv: 'Stations Surveillance',
                    surv_log: 'Surveillance Log',
                    View: 'View',
                    From: 'From',
                    To: 'To',
                    Temperature: 'Temperature',
                    Wattage: 'Wattage'
                }
            },
            gre: {
                translation: {
                    Home: 'Αρχική',
                    Users: 'Χρήστες',
                    Roles: 'Ρολοι',
                    Analytics: 'Aναλυτικά στοιχεία',
                    AccountSettings: 'Ρυθμίσεις λογαριασμού',
                    GoodMorning: 'Καλημέρα',
                    GoodEvening: 'Καλησπέρα',
                    Dashboard: 'Ταμπλό',
                    SelectLanguage: 'Επιλογή γλώσσας',
                    EnterCredsContinue: 'Εισάγεται τα στοιχεία σας για να συνεχίσετε',
                    LoginEmailUsername: 'Σύνδεση μέσω Email ή Όνομα Χρήστη',
                    Login: 'Σύνδεση',
                    Logout: 'Αποσύνδεση',
                    ForgotPassword: 'Ξέχασα τον κωδικό μου',
                    DontHaveAcount: 'Δεν έχετε λογαριασμό;',
                    LoginSuccess: 'Επιτυχία σύνδεσης',
                    SomethingWrong: 'Κάτι πήγε λάθος',
                    Email: 'Δ/νση Ηλ. Ταχυδρομίου',
                    Password: 'Κωδικός πρόσβασης',
                    ConfirmPassword: 'Επιβεβαιώση κωδικού πρόσβασης',
                    RequiredField: 'Υπορχρεωτικό πεδίο',
                    CreateAccount: 'Δημιουργία λογαριασμού',
                    YourCreds: 'Καταχωρήστε τα στοιχεία σας',
                    AcceptTerms: 'Αποδεχθείτε τους όρους χρήσης',
                    AcceptTermsError: 'Πρέπει να αποδεχθείτε τους όρους χρήσης',
                    AlreadyAccount: 'Έχετε ήδη λογαριασμό;',
                    SignUp: 'Εγγραφή',
                    Firstname: 'Όνομα',
                    Lastname: 'Επώνυμο',
                    PhoneNumber: 'Αριθμός τηλεφώνου',
                    PasswordsNotMatching: 'Οι κωδικοί δεν ταιριάζουν',
                    InvalidDate: 'Μη έγκυρη ημερομηνία',
                    InvalidPhone: 'Μη έγκυρος αριθμός τηλεφώνου',
                    InvalidEmail: 'Μη έγκυρο email',
                    SiteUnavailable: 'Η ιστοσελίδα είναι προσωρινά μη διαθέσιμη :(',
                    PageNotFound: 'Η σελίδα δεν βρέθηκε :(',
                    PageUnderConstruction: '🚚 Η σελίδα είναι υπό κατασκευή 🚚',
                    Authentication: 'Εφαρμογή',
                    Dashboards: 'Διαγράμματα',
                    'Energy & Telecommunication': 'Ενεργειακά & Τηλεπικοινωνιακά Δεδομένα',
                    Energy: 'Ενεργειακά Δεδομένα',
                    Telecommunication: 'Τηλεπικοινωνιακά Δεδομένα',
                    Station: 'Σταθμός',
                    Year: 'Έτος',
                    Month: 'Μήνας',
                    Indicator: 'Δείκτης',
                    January: 'Ιανουάριος',
                    February: 'Φεβρουάριος',
                    March: 'Μάρτιος',
                    April: 'Απρίλιος',
                    May: 'Μάιος',
                    June: 'Ιούνιος',
                    July: 'Ιούλιος',
                    August: 'Αύγουστος',
                    September: 'Σεπτέμβριος',
                    October: 'Οκτώβριος',
                    November: 'Νοέμβριος',
                    December: 'Δεκέμβριος',
                    Cluster: 'Σύμπλεγμα',
                    Technology: 'Τεχνολογία',
                    FrequencyMHz: 'Συχνότητα(MHz)',
                    OptMethod: 'Μέθοδος Βελτιστοποίησης',
                    Maps: 'Χάρτες',
                    InitialMap: 'Αρχικός Χάρτης',
                    OptimizedMap: 'Βελτιστοποιημένος Χάρτης',
                    SideBySideMaps: 'Αρχικός & Βελτιστοποιημένος Χάρτης',
                    ClusterOpt: 'Βελτιστοποιήση συμπλεγμάτων',
                    base_station: 'Σταθμός Βάσης',
                    model: 'Μοντέλο',
                    EnergySimulation: 'Ενεργειακή Προσομοίωση',
                    EffServer: 'Δεδομένα Διακομιστή Αποδοτικότητας',
                    stations_surv: 'Παρακολούθηση Σταθμών',
                    surv_log: 'Αρχείο Παρακολουθήσεων',
                    View: 'Εμφάνιση',
                    From: 'Από',
                    To: 'Εώς',
                    Temperature: 'Θερμοκρασία',
                    Wattage: 'Ισχύς'
                }
            }
        }
    });

export default i18n;
