import { Grid } from '@mui/material';
import { authorizeBDA, fetchMessages } from 'apis/cslab';
import { Dropdown } from 'qubCommon/Inputs';
import { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import AreaChartDiagram from '../MainDashboard/Components/AreaChartDiagram';
import ChartWrapper from '../MainDashboard/Components/ChartWrapper';
import { FlexGrid, FlexGridColumn, FlexGridCellTemplate } from '@grapecity/wijmo.react.grid';
import * as wjcCore from '@grapecity/wijmo';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

const LiveData = () => {
    const [tempData, setTempData] = useState([]);
    const [wattData, setWattData] = useState([]);
    const tempGridRef = useRef();
    const wattGridRef = useRef();

    const { t } = useTranslation();

    async function fetchData(type) {
        const urlParams = new URLSearchParams();
        urlParams.append('filters', `message_type:${type}`);
        let res;

        try {
            res = await fetchMessages('select', urlParams);
        } catch {
            authorizeBDA()
                .then(async (res) => {
                    console.log(res);
                    res = await fetchMessages('select', urlParams);
                })
                .catch((err) => console.log(err));
        }
        let datetimeObject;
        let valueObject;
        let datetime;
        let value;
        try {
            datetimeObject = res[0]?.tuple?.filter((obj) => {
                return obj.key === 'datetime';
            });
            valueObject = res[0]?.tuple?.filter((obj) => {
                return obj.key === 'payload';
            });
        } catch {
            console.log('error finding objects');
        }
        try {
            datetime = format(new Date(datetimeObject[0].value), 'dd/MM/yyyy HH:mm');
            value = valueObject[0].value;
            if (type === 'temperature') {
                setTempData((data) => [...data, { datetime, value, unit: '°C' }]);
            }
            if (type === 'wattage') setWattData((data) => [...data, { datetime, value, unit: 'W' }]);
        } catch {
            console.log('error values');
        }

        tempGridRef?.current?.control?.refreshCells();
    }

    useEffect(() => {
        fetchData('temperature');
        fetchData('wattage');
        const interval = setInterval(() => {
            fetchData('temperature');
            fetchData('wattage');
        }, 10000);
        return () => {
            clearInterval(interval);
        };
    }, []);

    const days = [];
    for (let i = 1; i <= 30; i += 1) {
        const obj = {
            value: i,
            label: i.toString()
        };
        days.push(obj);
    }

    useEffect(() => {
        console.log(tempData, wattData);
    }, [tempData, wattData]);
    return (
        <Grid container spacing={2}>
            <ChartWrapper xs={12} md={6} title={`${t('Temperature')} (°C)`}>
                <FlexGrid
                    style={{ height: '660px' }}
                    selectionMode="None"
                    ref={tempGridRef}
                    initialized={(flex) => {
                        flex.itemFormatter = (panel, r, c, cell) => {
                            if (panel.cellType === 3) {
                                cell.innerHTML = `<div class='row-header'> ${panel.rows[r]?.index + 1}</div>`;
                            }
                        };
                    }}
                    itemsSource={tempData}
                    itemsSourceChanged={(flex) => {
                        try {
                            const lastRowIndex = flex.collectionView?.itemCount - 1;
                            flex.scrollIntoView(lastRowIndex, 0);
                            const lastRow = flex.rows[lastRowIndex];
                            lastRow.cssClass = 'animategreen';
                        } catch {
                            console.log('changed Tab');
                        }
                    }}
                >
                    <FlexGridColumn header="Timestamp" width="*" binding="datetime" isReadOnly />
                    <FlexGridColumn header="Value" binding="value" isReadOnly />
                    <FlexGridColumn header="Unit" width={100} binding="unit" isReadOnly />
                </FlexGrid>
            </ChartWrapper>
            <ChartWrapper xs={12} md={6} title={`${t('Wattage')} (W)`}>
                <FlexGrid
                    style={{ height: '660px' }}
                    selectionMode="None"
                    ref={wattGridRef}
                    itemsSource={wattData}
                    initialized={(flex) => {
                        flex.itemFormatter = (panel, r, c, cell) => {
                            if (panel.cellType === 3) {
                                cell.innerHTML = `<div class='row-header'> ${panel.rows[r]?.index + 1}</div>`;
                            }
                        };
                    }}
                    itemsSourceChanged={(flex) => {
                        try {
                            const lastRowIndex = flex.collectionView?.itemCount - 1;
                            flex.scrollIntoView(lastRowIndex, 0);
                            const lastRow = flex.rows[lastRowIndex];
                            lastRow.cssClass = 'animategreen';
                        } catch {
                            console.log('changed Tab');
                        }
                    }}
                >
                    <FlexGridColumn header="Timestamp" width="*" binding="datetime" isReadOnly />
                    <FlexGridColumn header="Value" binding="value" isReadOnly />
                    <FlexGridColumn header="Unit" width={100} binding="unit" isReadOnly />
                </FlexGrid>
            </ChartWrapper>
        </Grid>
    );
};

export default LiveData;
