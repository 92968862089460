import { Dropdown } from 'qubCommon/Inputs';
import { Box, Grid } from '@mui/material';
import { FormProvider } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { stations, models } from '../data';

const MainFilters = (props) => {
    const { methods, submit, tab } = props;
    const [monthOptions, setMonthOptions] = useState([]);
    const { t } = useTranslation();

    const filters = { stations, models };

    return (
        <FormProvider {...methods}>
            <Grid container spacing={2} flexDirection="row" sx={{ display: 'flex', gap: 2, padding: 2 }}>
                <Dropdown
                    name="station"
                    label={t('base_station')}
                    options={filters?.stations}
                    customOnChange={submit}
                    defaultValue={filters?.stations[0]?.value}
                />

                <Dropdown
                    name="model"
                    label={t('model')}
                    options={
                        [4, 5, 6, 7, 8, undefined].includes(methods.watch('station'))
                            ? filters?.models
                            : filters?.models?.filter((model) => model.value === 1)
                    }
                    defaultValue={filters?.models[0]?.value}
                    customOnChange={submit}
                />
            </Grid>
        </FormProvider>
    );
};

export default MainFilters;
