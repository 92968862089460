import { styled } from '@mui/material/styles';
import { TextField } from '@mui/material';
import { useSelector } from 'react-redux';

export const CssTextField = styled(TextField, {
    shouldForwardProp: (prop) =>
        prop !== 'fixedPadding' &&
        prop !== 'fixedPaddingLeft' &&
        prop !== 'hasBackground' &&
        prop !== 'disabledStyling' &&
        prop !== 'focusedStyling' &&
        prop !== 'hoverStyling'
})(({ fixedPadding, fixedPaddingLeft, hasBackground, disabledStyling, focusedStyling, hoverStyling }) => {
    const styles = useSelector((state) => state.customization);

    return {
        '& .MuiInput-input': {
            padding: 0
        },
        '& .MuiInputBase-input.Mui-disabled': {
            WebkitTextFillColor: 'rgba(0,0,0,1)'
        },
        '& .MuiInput-root': {
            backgroundColor: styles?.fieldsBgColor ?? '#EDF2F8',
            boxShadow: styles?.fieldsBoxShadow ?? '0px 4px 10px rgba(0, 0, 0, 0.2)',
            borderRadius: styles?.fieldsBorderRadius ?? '10px',
            padding: fixedPadding ?? 10,
            border: `${styles?.fieldsBorder} ${styles?.fieldsBorderColor}` ?? '2px solid #EDF2F8',
            '&:hover': {
                boxShadow: styles?.fieldsBoxShadow ?? '0px 4px 10px rgba(0, 0, 0, 0.2)',
                padding: 8,
                border: '2px solid #FFD35E' ?? '2px solid #FFD35E',
                ...hoverStyling
            },
            // "&:hover fieldset": {
            //   borderColor: "#FFD35E",
            // },
            '&.Mui-focused ': {
                background: styles?.focusedFieldsBgColor ?? '#FFECBA',
                boxShadow: styles?.fieldsBoxShadow ?? '0px 4px 10px rgba(0, 0, 0, 0.2)',
                border: `${styles?.fieldsBorder} ${styles?.focusedFieldsBorderColor}` ?? '2px solid #FFD35E',
                ...focusedStyling
            },
            '&.Mui-disabled ': {
                background: styles?.disabledFieldsBgColor ?? '#EAEAEA',
                border: `${styles?.fieldsBorder} ${styles?.disabledFieldsBorderColor}` ?? '2px solid #D9D9D9',
                boxShadow: styles?.fieldsBoxShadow ?? '0px 4px 10px rgba(0, 0, 0, 0.2)',
                ...disabledStyling
            },
            '&.Mui-error ': {
                background: styles?.errorFieldsBgColor ?? '#FFF2F2',
                border: `${styles?.fieldsBorder} ${styles?.errorFieldsBorderColor}` ?? '2px solid #D10029'
            },
            '&.Mui-focused fieldset ': {
                borderColor: styles?.focusedFieldsBorderColor ?? '#FFD35E'
            }
        },
        '& .MuiOutlinedInput-root': {
            backgroundColor: styles?.fieldsBgColor ?? '#EDF2F8',
            boxShadow: styles?.fieldsBoxShadow ?? '0px 4px 10px rgba(0, 0, 0, 0.2)',
            borderRadius: styles?.fieldsBorderRadius ?? '10px',
            padding: fixedPadding ?? 0,
            '& label': {
                color: styles?.labelFieldsColor ?? 'gray'
            },
            '& > fieldset': {
                borderColor: styles?.fieldsBgColor ?? '#EDF2F8'
            },
            '&:hover': {
                boxShadow: styles?.fieldsBoxShadow ?? '0px 4px 10px rgba(0, 0, 0, 0.2)',
                ...hoverStyling,
                '& > fieldset': {
                    border: `${styles?.fieldsBorder} ${styles?.hoverFieldsBorderColor}` ?? '2px solid #FFD35E'
                }
            },

            '&.Mui-focused': {
                background: styles?.focusedFieldsBgColor ?? '#FFECBA',
                boxShadow: styles?.fieldsBoxShadow ?? '0px 4px 10px rgba(0, 0, 0, 0.2)',
                ...focusedStyling,
                '& > fieldset': {
                    border: `${styles?.fieldsBorder} ${styles?.focusedFieldsBorderColor}` ?? '2px solid #FFD35E'
                }
            },
            '&.Mui-error ': {
                background: styles?.errorFieldsBgColor ?? '#FFF2F2',
                '& > fieldset': {
                    border: `${styles?.fieldsBorder} ${styles?.errorFieldsBorderColor}` ?? '2px solid #D10029'
                }
            },
            '&.Mui-disabled ': {
                background: styles?.disabledFieldsBgColor ?? '#EAEAEA',
                '& > fieldset': {
                    border: `${styles?.fieldsBorder} ${styles?.disabledFieldsBorderColor}` ?? '2px solid #D9D9D9',
                    ...disabledStyling
                },
                boxShadow: styles?.fieldsBoxShadow ?? '0px 4px 10px rgba(0, 0, 0, 0.2)'
            }
        }
    };
});
