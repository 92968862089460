import { Stack, Skeleton } from '@mui/material';

const MainFiltersSkeleton = () => (
    <Stack direction="row">
        <Skeleton variant="text" width={200} />
        <Skeleton variant="text" width={200} />
        <Skeleton variant="text" width={200} />
    </Stack>
);

export default MainFiltersSkeleton;
