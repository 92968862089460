import { Line, LineChart, CartesianGrid, Tooltip, XAxis, YAxis, ResponsiveContainer } from 'recharts';
import format from 'date-fns/format';

const AreaChartDiagram = (props) => {
    const { data, lines, xProperty, height } = props;

    return (
        <ResponsiveContainer debounce={300} width="100%" height={height}>
            <LineChart data={data}>
                <XAxis dataKey={xProperty} tickFormatter={(tick) => format(new Date(tick), 'dd/MM/yyyy HH:mm')} />
                <YAxis />
                <CartesianGrid strokeDasharray="3 3" />
                <Tooltip labelFormatter={(value) => format(new Date(value), 'dd/MM/yyyy HH:mm')} />
                {lines.map((line) => (
                    <Line
                        key={line?.dataKey}
                        type="monotone"
                        dataKey={line?.dataKey}
                        stroke={line?.stroke}
                        // fillOpacity={0.4}
                        dot={false}
                        // fill={line?.stroke}
                    />
                ))}
            </LineChart>
        </ResponsiveContainer>
    );
};

export default AreaChartDiagram;
