/* eslint-disable */
// react router
import { Outlet, useNavigate, useParams } from 'react-router-dom';
// prop types
import PropTypes from 'prop-types';
// mui
import { Box, Stack, Tab, Skeleton, Tabs, Grid, Typography } from '@mui/material';
// react query
import { useQuery } from 'react-query';
// apis
import { dashboard } from 'apis';

// hoc

import { useTranslation } from 'react-i18next';

import { FormProvider, useForm } from 'react-hook-form';
import { Dropdown } from 'qubCommon/Inputs';
import { useEffect, useState } from 'react';
import Conditional from 'common/Conditional';
import LoadingComponent from 'ui-component/LoadingComponent';
import MainFilters from './Components/MainFilters';
import MainFiltersSkeleton from './Components/MainFiltersSkeleton';
import LocalHTMLRenderer from 'common/LocalHTMLRenderer';
import { getSimulationData } from 'apis/controllers/dashboard';

const ClusterOptimization = ({ children }) => {
    const [tab, setTab] = useState('init');
    const { t } = useTranslation();
    const methods = useForm();
    const [data, setData] = useState(null);
    const [path, setPath] = useState('');
    const [path2, setPath2] = useState('');
    const [simulationData, setSimulationData] = useState(null);
    const [loading, setLoading] = useState(false);
    const isObjectEmpty = (objectName) => {
        return Object.keys(objectName).length === 0;
    };

    const images = require.context('../../../assets/images/', true);
    const loadImage = (imageName) => images(`${imageName}`).default;

    const filtersQuery = useQuery(
        'cluster-optimization-filters',
        async () => {
            const result = await dashboard.getClusterOptParams();
            return result;
        },
        { refetchOnWindowFocus: false }
    );

    console.log(filtersQuery, 'MelloAngelos');

    const submit = async (newTab) => {
        setLoading(true);
        const { cluster, frequency, map, opt_method, technology } = methods.getValues();
        const newPath = `${cluster}_${technology}_${frequency}_${opt_method}_${map}`;
        const res = await getSimulationData(methods.getValues());
        setSimulationData(res);
        setPath('');
        setPath2('');
        console.log(res);
        try {
            var img = require(`../../../assets/images/cluster_${newPath}_init.jpg`);
            var img2 = require(`../../../assets/images/cluster_${newPath}_opt.jpg`);
            setPath(img);
            setPath2(img2);
        } catch {
            console.log('error image');
        }
        setLoading(false);
    };

    const handleChange = (_, newTab) => {
        setPath('bigopt-logo.png');
        setTab(newTab);
        submit(newTab);
    };

    useEffect(() => {
        if (!isObjectEmpty(methods.getValues())) {
            submit();
        }
    }, [filtersQuery?.data, tab]);

    return (
        <>
            <LoadingComponent loading={loading} />
            <Box sx={{ height: '100%' }}>
                {filtersQuery?.data ? (
                    <MainFilters filters={filtersQuery?.data} methods={methods} status={filtersQuery?.status} submit={submit} tab={tab} />
                ) : (
                    <MainFiltersSkeleton />
                )}
                <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: 2 }}>
                    <Tabs value={tab} onChange={handleChange} aria-label="role-tabs">
                        <Tab label={t('InitialMap')} value="init" />
                        <Tab label={t('OptimizedMap')} value="opt" />
                        <Tab label={t('SideBySideMaps')} value="sideBySideMaps" />
                    </Tabs>
                </Box>
                {simulationData?.length > 0 ? (
                    <Grid container spacing={2}>
                        <Grid container item xs={12} sm={12} md={8} lg={8} spacing={2}>
                            <Grid item xs={12}>
                                <Typography align="center" variant="h4">
                                    {methods.getValues('opt_method') === 1 ? 'Tx Power Reduction' : 'Cell Shutdown'}
                                </Typography>
                            </Grid>
                            {['init', 'sideBySideMaps'].includes(tab) && (
                                <Grid item xs={tab === 'init' ? 12 : 6}>
                                    {tab === 'sideBySideMaps' && (
                                        <Typography align="center" variant="h5">
                                            Initial Map
                                        </Typography>
                                    )}
                                    <img src={path} alt="Initial Map not found" />
                                </Grid>
                            )}
                            {['opt', 'sideBySideMaps'].includes(tab) && (
                                <Grid item xs={tab === 'opt' ? 12 : 6}>
                                    {tab === 'sideBySideMaps' && (
                                        <Typography align="center" variant="h5">
                                            Optimized Map
                                        </Typography>
                                    )}
                                    <img src={path2} alt="Optimized Map not found" />
                                </Grid>
                            )}
                        </Grid>
                        {simulationData && (
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                                    <thead>
                                        <tr>
                                            <th colSpan="2" style={{ borderBottom: '2px solid #000', paddingBottom: '16px' }}>
                                                Simulation Details
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody style={{ marginTop: '4px' }}>
                                        {simulationData.map((row) => (
                                            <tr key={row?.label}>
                                                <td style={{ border: '1px solid #000', padding: '8px' }}>{row?.label}</td>
                                                <td style={{ border: '1px solid #000', padding: '8px' }}>{row?.value}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </Grid>
                        )}
                    </Grid>
                ) : (
                    <Grid item xs={12}>
                        <Typography mt={15} align="center" variant="h3">
                            Δεν βρέθηκαν δεδομένα για τις παραπάνω επιλογές παραμέτρων!
                        </Typography>
                    </Grid>
                )}
            </Box>
        </>
    );
};

export default ClusterOptimization;
