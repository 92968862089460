import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const useTab = () => {
    const [tab, setTab] = useState('details');
    const { pathname } = useLocation();

    useEffect(() => {
        // ONLY WORKS IF TAB NAME IS THE SAME AS LAST ITEM OF ROUTE
        const tabAccordingToRoute = pathname.split('/').pop();
        console.log(tabAccordingToRoute);
        setTab(tabAccordingToRoute);
    }, [pathname]);

    return [tab, setTab];
};
