import { Box, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import format from 'date-fns/format';
import AreaChartDiagram from './Components/AreaChartDiagram';
import ChartWrapper from './Components/ChartWrapper';

const lines1 = [
    { dataKey: 'energy_mean', stroke: '#8884d8', fill: 'url(#color1)' },
    { dataKey: 'energy_pred', stroke: '#82ca9d', fill: 'url(#color2)' }
];

const lines2 = [
    { dataKey: 'energy_mean', stroke: '#8884d8', fill: 'url(#color1)' },
    { dataKey: 'energy_pred_on_pred', stroke: '#82ca9d', fill: 'url(#color2)' }
];

const lines3 = [
    { dataKey: 'energy_mean', stroke: '#8884d8', fill: 'url(#color1)' },
    { dataKey: 'energy_pred', stroke: '#82ca9d', fill: 'url(#color2)' },
    { dataKey: 'energy_pred_on_pred', stroke: '#80009a', fill: 'url(#color3)' }
];

const EnergyDashboard = (props) => {
    const { methods, data } = props;
    const [showPredDiagram1, setShowPredDiagram1] = useState(true);
    const [showPredDiagram2, setShowPredDiagram2] = useState(true);
    const [diagram1Lines, setDiagram1Lines] = useState(lines1);
    const [diagram2Lines, setDiagram2Lines] = useState(lines2);

    useEffect(() => {
        if (showPredDiagram1) setDiagram1Lines(lines1);
        else setDiagram1Lines([lines1[0]]);
    }, [showPredDiagram1]);

    useEffect(() => {
        if (showPredDiagram2) setDiagram2Lines(lines2);
        else setDiagram2Lines([lines2[0]]);
    }, [showPredDiagram2]);

    const handleShowHideDiagram1 = () => {
        setShowPredDiagram1(!showPredDiagram1);
    };

    const handleShowHideDiagram2 = () => {
        setShowPredDiagram2(!showPredDiagram2);
    };

    if (data)
        return (
            <Grid container spacing={2}>
                <ChartWrapper xs={12} md={6} title="Mean and Pred" handleShowHide={handleShowHideDiagram1} showPred={showPredDiagram1}>
                    <AreaChartDiagram data={data} lines={diagram1Lines} height={280} xProperty="PERIOD_START_TIME" />
                </ChartWrapper>
                <ChartWrapper
                    xs={12}
                    md={6}
                    title="Mean and Pred on Pred"
                    handleShowHide={handleShowHideDiagram2}
                    showPred={showPredDiagram2}
                >
                    <AreaChartDiagram data={data} lines={diagram2Lines} height={280} xProperty="PERIOD_START_TIME" />
                </ChartWrapper>
                <ChartWrapper xs={12} md={12} title="Mean/Pred/Pred on Pred">
                    <AreaChartDiagram data={data} lines={lines3} height={280} xProperty="PERIOD_START_TIME" />
                </ChartWrapper>
            </Grid>
        );
    return <div />;
};

export default EnergyDashboard;
