// react router
import { Outlet, useNavigate, useParams } from 'react-router-dom';
// prop types
import PropTypes from 'prop-types';
// mui
import { Box, Stack, Tab, Skeleton, Tabs } from '@mui/material';
// react query
import { useQuery } from 'react-query';
// apis
import { dashboard } from 'apis';

// hoc

import { useTranslation } from 'react-i18next';

import { FormProvider, useForm } from 'react-hook-form';
import { Dropdown } from 'qubCommon/Inputs';
import { useEffect, useState } from 'react';
import Conditional from 'common/Conditional';
import LoadingComponent from 'ui-component/LoadingComponent';

import EnergyCube from './Components/EnergyCube';

const DataCube = ({ children }) => {
    const { id } = useParams();
    const [tab, setTab] = useState('energy');
    const { t } = useTranslation();
    const methods = useForm();
    const [energyData, setEnergyData] = useState([]);
    const [teleData, setTeleData] = useState([]);
    const [panel, setPanel] = useState(null);
    const [loading, setLoading] = useState(false);
    const filtersQuery = useQuery(
        'dashboard-filters',
        async () => {
            const result = await dashboard.getChartsParams();
            return result;
        },
        { refetchOnWindowFocus: false }
    );

    const submit = async (newTab) => {
        if (!newTab) {
            setLoading(true);
            setEnergyData(null);
            setTeleData(null);
            const res = await dashboard.getCubeData({ type: 'energy' });
            if (tab === 'energy') setEnergyData(res);
            else if (tab === 'telecommunication') setTeleData(res);
            setLoading(false);
        }
        if (newTab) {
            setLoading(true);
            setEnergyData(null);
            setTeleData(null);
            const res = await dashboard.getCubeData({ type: 'energy' });
            if (newTab === 'energy') {
                setEnergyData(res);
            } else if (newTab === 'telecommunication') setTeleData(res);
            setLoading(false);
        }
    };

    useEffect(() => {
        submit();
    }, []);

    const handleChange = (_, newTab) => {
        setTab(newTab);
        submit(newTab);
    };

    const isObjectEmpty = (objectName) => {
        return Object.keys(objectName).length === 0;
    };

    useEffect(() => {
        if (!isObjectEmpty(methods.getValues())) {
            console.log(methods.getValues());
        }
    }, [filtersQuery?.data]);

    return (
        <>
            <LoadingComponent loading={loading} />
            <Box sx={{ height: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: 2 }}>
                    <Tabs value={tab} onChange={handleChange} aria-label="role-tabs">
                        <Tab label={t('Energy')} value="energy" />
                        <Tab label={t('Telecommunication')} value="tele" />
                    </Tabs>
                </Box>
                <Conditional condition={energyData !== null}>
                    {tab === 'energy' ? <EnergyCube data={energyData} /> : <div>chart</div>}
                </Conditional>
            </Box>
        </>
    );
};

export default DataCube;
