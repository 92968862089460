// react router
import { Outlet, useNavigate, useParams } from 'react-router-dom';
// prop types
import PropTypes from 'prop-types';
// mui
import { Box, Stack, Tab, Skeleton, Tabs, Typography } from '@mui/material';
// react query
import { useQuery } from 'react-query';
// apis
import { dashboard } from 'apis';

// hoc

import { useTranslation } from 'react-i18next';

import { FormProvider, useForm } from 'react-hook-form';
import { Dropdown } from 'qubCommon/Inputs';
import { useEffect, useState } from 'react';
import Conditional from 'common/Conditional';
import LoadingComponent from 'ui-component/LoadingComponent';
import MainFilters from './Components/MainFilters';
import MainFiltersSkeleton from './Components/MainFiltersSkeleton';
import LocalHTMLRenderer from 'common/LocalHTMLRenderer';
import { stations } from './data';

const EnergySimulation = ({ children }) => {
    const { t } = useTranslation();
    const methods = useForm();
    const [path, setPath] = useState('/htmlFiles/4_1.html');
    const [loading, setLoading] = useState(false);
    const [structure, setStructure] = useState('Bric Wall 25cm, Telco 4KW');
    const isObjectEmpty = (objectName) => {
        return Object.keys(objectName).length === 0;
    };

    const submit = async () => {
        setLoading(true);
        console.log(methods.getValues());
        const station = methods.getValues('station');
        const helpStructure = stations.find((item) => item.value === station)?.additionalInfo;
        setStructure(helpStructure);
        if (![4, 5, 6, 7, 8].includes(station)) methods.setValue('model', 1);
        const model = methods.getValues('model');
        setPath(`/htmlFiles/${station}_${model}.html`);
        console.log(helpStructure);
        setLoading(false);
    };

    useEffect(() => {
        submit();
    }, []);

    return (
        <>
            <LoadingComponent loading={loading} />
            <Box sx={{ height: '100%' }}>
                <MainFilters methods={methods} submit={submit} />

                <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: 2 }}>
                    <Typography variant="h4">
                        {t('Structure')}: {structure}
                    </Typography>
                </Box>
                <iframe id="serviceFrameSend" title="sample" src={path} height="84%" width="100%" frameBorder="0" />
            </Box>
        </>
    );
};

export default EnergySimulation;
