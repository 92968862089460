import { Box, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import format from 'date-fns/format';
import AreaChartDiagram from './Components/AreaChartDiagram';
import ChartWrapper from './Components/ChartWrapper';

const TeleDashboard = (props) => {
    const { methods, data } = props;

    if (data)
        return (
            <AreaChartDiagram
                height={600}
                data={data}
                lines={[
                    { dataKey: Object.keys(data[0])[1], stroke: '#8884d8', fill: 'url(#color1)' },
                    { dataKey: Object.keys(data[0])[2], stroke: '#82ca9d', fill: 'url(#color2)' }
                ]}
                xProperty="PERIOD_START_TIME"
            />
        );
    return <div />;
};

export default TeleDashboard;
