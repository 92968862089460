// react router
import { Outlet, useNavigate, useParams } from 'react-router-dom';
// prop types
import PropTypes from 'prop-types';
// mui
import { Typography, Box, Stack, Tab, Divider, Skeleton, Tabs, Button } from '@mui/material';
import { TabContext, TabList } from '@mui/lab';
// react query
import { useQuery } from 'react-query';
// apis
import { backoffice, dashboard } from 'apis';

// hoc
import { withSkeleton } from '@qubiteq/qublib';

import { useTranslation } from 'react-i18next';

import { useBreadcrumbs } from 'hooks/useBreadcrumbs';

// hooks
import { useTab } from 'hooks/useTab';
import { dashboardApi } from 'apis/controllers/dashboard';
import { FormProvider, useForm } from 'react-hook-form';
import { Dropdown } from 'qubCommon/Inputs';
import { useEffect, useState } from 'react';
import EnergyDashboard from './EnergyDashboard';
import Conditional from 'common/Conditional';
import LoadingComponent from 'ui-component/LoadingComponent';
import TeleDashboard from './TeleDashboard.jsx';

const MainFilters = (props) => {
    const { filters, methods, submit, tab } = props;
    const [monthOptions, setMonthOptions] = useState([]);
    const { t } = useTranslation();

    useEffect(() => {
        const options = filters?.dates.find((date) => filters?.dates[0]?.value === date.value)?.months;
        if (options?.length > 0) {
            setMonthOptions(options);
            methods.setValue('month', options[0]?.value);
        }
    }, []);

    const changeYear = () => {
        const options = filters?.dates.find((date) => methods.watch('year') === date.value)?.months;
        if (options?.length > 0) {
            setMonthOptions(options);
            methods.setValue('month', options[0]?.value);
            submit();
        }
    };
    if (filters?.dates)
        return (
            <Box>
                <FormProvider {...methods}>
                    <Stack direction="row" spacing={2}>
                        <Dropdown
                            name="station"
                            label={t('Station')}
                            options={filters?.stations}
                            customOnChange={submit}
                            defaultValue={filters?.stations[0].value}
                        />
                        <Dropdown
                            name="year"
                            label={t('Year')}
                            options={filters?.dates}
                            customOnChange={changeYear}
                            defaultValue={filters?.dates[0]?.value}
                        />
                        {monthOptions?.length > 0 && (
                            <Dropdown name="month" label={t('Month')} options={monthOptions} customOnChange={submit} />
                        )}
                        {tab === 'telecommunication' && (
                            <Dropdown
                                name="value"
                                label={t('Indicator')}
                                options={filters?.telecolumns}
                                defaultValue={filters?.telecolumns[0]?.value}
                                customOnChange={submit}
                            />
                        )}
                    </Stack>
                </FormProvider>
            </Box>
        );
    return <div>loading</div>;
};

const MainFiltersSkeleton = () => (
    <Stack direction="row">
        <Skeleton variant="text" width={200} />
        <Skeleton variant="text" width={200} />
        <Skeleton variant="text" width={200} />
    </Stack>
);

const MainDashboard = ({ children }) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [tab, setTab] = useState('energy');
    const { t } = useTranslation();
    const methods = useForm();
    const [energyData, setEnergyData] = useState(null);
    const [teleData, setTeleData] = useState(null);
    const [loading, setLoading] = useState(false);
    const filtersQuery = useQuery(
        'dashboard-filters',
        async () => {
            const result = await dashboard.getChartsParams();
            return result;
        },
        { refetchOnWindowFocus: false }
    );

    const submit = async (newTab) => {
        if (!newTab) {
            setLoading(true);
            setEnergyData(null);
            setTeleData(null);
            const res = await dashboard.getChartsData({ ...methods.getValues(), type: tab });
            console.log(res);
            if (tab === 'energy') setEnergyData(res);
            else if (tab === 'telecommunication') setTeleData(res);
            setLoading(false);
        }
        if (newTab) {
            setLoading(true);
            setEnergyData(null);
            setTeleData(null);
            if (!methods?.getValues('value')) methods.setValue('value', filtersQuery?.data?.telecolumns[0]?.value);
            const res = await dashboard.getChartsData({ ...methods.getValues(), type: newTab });
            console.log(res);
            if (newTab === 'energy') setEnergyData(res);
            else if (newTab === 'telecommunication') setTeleData(res);
            setLoading(false);
        }
    };

    const handleChange = (_, newTab) => {
        setTab(newTab);
        submit(newTab);
    };

    const isObjectEmpty = (objectName) => {
        return Object.keys(objectName).length === 0;
    };

    useEffect(() => {
        if (!isObjectEmpty(methods.getValues())) {
            console.log(methods.getValues());
            submit();
        }
    }, [filtersQuery?.data]);

    return (
        <>
            <LoadingComponent loading={loading} />
            <Box sx={{ height: '100%' }}>
                <Box sx={{ height: '10%', minHeight: 60 }}>
                    {filtersQuery?.data ? (
                        <MainFilters
                            filters={filtersQuery?.data}
                            methods={methods}
                            status={filtersQuery?.status}
                            submit={submit}
                            tab={tab}
                        />
                    ) : (
                        <MainFiltersSkeleton />
                    )}
                </Box>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: 2 }}>
                    <Tabs value={tab} onChange={handleChange} aria-label="role-tabs">
                        <Tab label={t('Energy')} value="energy" />
                        <Tab label={t('Telecommunication')} value="telecommunication" />
                    </Tabs>
                </Box>
                <Conditional condition={!!methods.getValues('month')}>
                    {tab === 'energy' ? <EnergyDashboard methods={methods} data={energyData} /> : <TeleDashboard data={teleData} />}
                </Conditional>
            </Box>
        </>
    );
};

export default MainDashboard;
