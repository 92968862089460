// React Imports
import { useState, forwardRef, createRef, useEffect } from 'react';

// MUI Imports
import { InputAdornment, IconButton, Stack, Tooltip } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { DateRangeSharp } from '@mui/icons-material';

// Other Libraries
import format from 'date-fns/format';
import { useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

// Project Imports
import { formatToShowDate, inDateRange } from 'qubCommon/helperMethods/dateHelper';
import { CssTextField } from './CssTextField';
import { parse } from 'date-fns';
import RowLabel from './RowLabel';
import ColumnLabel from './ColumnLabel';
import { useFormContext, useController } from 'react-hook-form';

const DateInput = ({
    defaultValue = '',
    dateInUse = false,
    locked = false,
    label = '',
    labelWidth = 120,
    variant = 'standard',
    cmpyrsValidation = false,
    rowDirection = false,
    name,
    blurFn,
    tooltip,
    dateFrom = null,
    dateTo = null,
    ...other
}) => {
    const dateFormat = {
        format: 'dd/MM/yyyy',
        d: 0,
        m: 1,
        y: 2
    };

    const { control, setValue } = useFormContext();
    const { field, fieldState } = useController({ name, control, defaultValue });
    const [input, setInput] = useState(defaultValue ? format(new Date(defaultValue), dateFormat?.format) : '');
    const [datePickerValue, setDatePickerValue] = useState(
        defaultValue ? format(new Date(defaultValue), dateFormat?.format) : format(new Date(), dateFormat?.format)
    );
    const dateRef = createRef();

    const CustomDatePicker = forwardRef(({ value, onClick }, ref) => (
        <IconButton onClick={onClick} ref={ref}>
            <DateRangeSharp />
        </IconButton>
    ));

    useEffect(() => {
        if (defaultValue) {
            setValue(name, defaultValue, { shouldDirty: true });
            setInput(format(new Date(defaultValue), dateFormat?.format));
        }
    }, [defaultValue]);

    const handleDatePickerChange = (e) => {
        let eValid;
        if (dateInUse) eValid = inDateRange(e.getFullYear(), e.getMonth(), e.getDate(), dateFrom, dateTo, dateFormat?.format);
        else eValid = format(new Date(e.getFullYear(), e.getMonth(), e.getDate()), dateFormat?.format);
        const eFormatted = parse(eValid, dateFormat?.format, new Date());
        setInput(format(eFormatted, dateFormat?.format));
        setValue(name, format(eFormatted, 'yyyy-MM-dd'), {
            shouldDirty: true
        });
        setDatePickerValue(format(eFormatted, dateFormat?.format));
    };

    return (
        <Stack direction={rowDirection ? 'row' : 'column'}>
            {variant === 'standard' && (
                <>
                    {rowDirection ? ( // variant = 'standard'
                        <RowLabel label={label} />
                    ) : (
                        <Stack direction="row" spacing={0.5} alignItems="center">
                            <ColumnLabel label={label} />
                            {tooltip && (
                                <Tooltip title={tooltip}>
                                    <HelpOutlineIcon fontSize="small" />
                                </Tooltip>
                            )}
                        </Stack>
                    )}
                </>
            )}
            <CssTextField
                disabled={locked}
                size="small"
                variant={variant}
                onBlur={() => {
                    if (input) {
                        const datePicked = formatToShowDate(input, dateFormat, defaultValue, dateInUse, dateFrom, dateTo);
                        setInput(datePicked ?? '');
                        setValue(name, format(parse(datePicked, dateFormat.format, new Date()), 'yyyy-MM-dd'), { shouldDirty: true });
                        setDatePickerValue(datePicked ?? format(new Date(), dateFormat?.format));
                    }

                    if (blurFn) blurFn();
                }}
                value={input}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                onChange={(e) => setInput(e?.target?.value)}
                InputProps={
                    locked
                        ? { disableUnderline: true }
                        : {
                              disableUnderline: true,
                              endAdornment: (
                                  <InputAdornment position="end">
                                      <DatePicker
                                          minDate={dateFrom ? new Date(dateFrom) : new Date('1900-01-01')}
                                          maxDate={dateTo ? new Date(dateTo) : new Date('2100-01-01')}
                                          selected={parse(datePickerValue, dateFormat?.format, new Date())}
                                          onChange={(e) => handleDatePickerChange(e)}
                                          customInput={<CustomDatePicker ref={dateRef} />}
                                          showYearDropdown
                                      />
                                  </InputAdornment>
                              )
                          }
                }
                {...other}
            />
        </Stack>
    );
};

export default DateInput;
