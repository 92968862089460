import { Dropdown } from 'qubCommon/Inputs';
import { Box, Grid } from '@mui/material';
import { FormProvider } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const MainFilters = (props) => {
    const { filters, methods, submit, tab } = props;
    const [monthOptions, setMonthOptions] = useState([]);
    const { t } = useTranslation();

    return (
        <FormProvider {...methods}>
            <Grid container spacing={2} flexDirection="row" sx={{ display: 'flex', gap: 2, padding: 2 }}>
                <Dropdown
                    name="cluster"
                    label={t('Cluster')}
                    options={filters?.clusters}
                    customOnChange={submit}
                    defaultValue={filters?.clusters[0]?.value}
                />
                <Dropdown
                    name="technology"
                    label={t('Technology')}
                    options={filters?.technologies}
                    defaultValue={filters?.technologies[0]?.value}
                    customOnChange={() => {
                        if (methods.getValues('technology') === 1) methods.setValue('frequency', 1);
                        if (methods.getValues('technology') === 2) methods.setValue('frequency', 4);
                        if (methods.getValues('technology') === 3) methods.setValue('frequency', 2);
                        submit();
                    }}
                />
                <Dropdown
                    name="frequency"
                    label={t('FrequencyMHz')}
                    options={filters?.frequencies?.filter((freq) => {
                        if (methods.watch('technology') === undefined) return [1, 3].includes(freq.value);
                        if (methods.watch('technology') === 1) return [1, 3].includes(freq.value);
                        if (methods.watch('technology') === 2) return [4, 5].includes(freq.value);
                        if (methods.watch('technology') === 3) return [2, 3].includes(freq.value);
                        return true;
                    })}
                    defaultValue={filters?.frequencies[0]?.value}
                    customOnChange={submit}
                />

                <Dropdown
                    name="opt_method"
                    label={t('OptMethod')}
                    options={filters?.optMethods}
                    defaultValue={filters?.optMethods[0]?.value}
                    customOnChange={submit}
                />
                <Dropdown
                    name="map"
                    label={t('Maps')}
                    options={filters?.maps}
                    defaultValue={filters?.maps[0]?.value}
                    customOnChange={submit}
                />
            </Grid>
        </FormProvider>
    );
};

export default MainFilters;
