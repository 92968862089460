// react router
import { Outlet, useNavigate, useParams } from 'react-router-dom';
// prop types
import PropTypes from 'prop-types';
// mui
import { Box, Stack, Tab, Skeleton, Tabs, Grid, Divider } from '@mui/material';
// react query
import { useQuery } from 'react-query';
// apis
import { dashboard } from 'apis';

// hoc

import { useTranslation } from 'react-i18next';

import { FormProvider, useForm } from 'react-hook-form';
import { Dropdown } from 'qubCommon/Inputs';
import { useEffect, useState } from 'react';
import Conditional from 'common/Conditional';
import LoadingComponent from 'ui-component/LoadingComponent';
import LiveData from './LiveData';
import DataLog from './DataLog';

const ClusterOptimization = ({ children }) => {
    const [tab, setTab] = useState('stations_surv');
    const { t } = useTranslation();
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const isObjectEmpty = (objectName) => {
        return Object.keys(objectName).length === 0;
    };

    // const filtersQuery = useQuery(
    //     'cluster-optimization-filters',
    //     async () => {
    //         const result = await dashboard.getClusterOptParams();
    //         return result;
    //     },
    //     { refetchOnWindowFocus: false }
    // );

    const submit = async (newTab) => {
        setData('hello i am dummy data');
    };

    // useEffect(() => {
    //     submit();
    // }, []);
    // useEffect(() => {
    //     if (!isObjectEmpty(methods.getValues())) {
    //         console.log(methods.getValues());
    //     }
    // }, [filtersQuery?.data]);

    const handleChange = (_, newTab) => {
        setTab(newTab);
        submit(newTab);
    };

    return (
        <>
            <LoadingComponent loading={loading} />
            <Box sx={{ height: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: 2 }}>
                    <Tabs value={tab} onChange={handleChange} aria-label="role-tabs">
                        <Tab label={t('stations_surv')} value="stations_surv" />
                        <Tab label={t('surv_log')} value="surv_log" />
                    </Tabs>
                </Box>
                {tab === 'stations_surv' && <LiveData />}
                {tab === 'surv_log' && <DataLog />}
            </Box>
        </>
    );
};

export default ClusterOptimization;
